// Não se esqueça de recompilar os estilos para poder ver as alterações feitas aqui!
//
// Você pode fazer isso pelo terminal de duas maneiras:
//
// npm run dev
// *Irá recompilar uma única vez todos os estilos e scripts do projeto*
//
// npm run watch-poll
// *Irá recompilar todos os estilos e scripts do projeto a primeira vez, e depois continuará rodando de fundo, e cada vez que você alterar um arquivo CSS ou JS e salvar,
//  ele irá compilar apenas o que você alterou!*
@font-face {
    font-family: "Objective";
    src: url("/fonts/Objective/Objective-Regular.woff2") format("woff2");
    // src: url("/fonts/Objective/Objective-Light.woff2") format("woff2");
    // src: url("/fonts/Objective/Objective-Bold.woff2") format("woff2");
}

@import "cores";

/*--------------------------------------------------------------
# menu
--------------------------------------------------------------*/

.whatsapp {
    width: 65px;
    right: 30px;
    height: auto;
    bottom: 30px;
    z-index: 999;
    position: fixed;
    filter: drop-shadow(0 0 2px #333);
    transition: all 0.7s;
}

.whatsapp:hover {
    transform: rotate(15deg);
    filter: drop-shadow(0 0 5px #333);
    /* transform: scale(1.1); */
}

.navbar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-color: #fff !important;
    z-index: 1001;
    transition: all 0.8s;
}
.navbar-light .navbar-nav .nav-link {
    color: black;
}
.navbar li {
    font-family: Objective;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}
.navbar-nav a:hover {
    font-weight: 900;
    color: #277bc0;
}
.navbar-light .navbar-nav .nav-link:hover {
    font-weight: 1000;
    color: #277bc0;
}
.btn-barra {
    font-family: Objective;
    font-size: 18px;
    background: rgb(70, 190, 216);
    background: linear-gradient(
        90deg,
        rgba(70, 190, 216, 1) 0%,
        rgba(39, 123, 192, 1) 100%
    );
    border-radius: 10px;
    padding: 10px 30px;

    span {
        color: #fff;
        font-weight: 500;
    }
}

.btn-barra:hover {
    background: #46bed8;
    transition-duration: 0.3s;
    transform: scale(1.1);
}

.menu-icon {
    font-size: 20px;
    color: #000;
}

.dropdown-menu {
    border: none;
    filter: drop-shadow(0px 10px 14px rgb(151, 151, 151));
    padding-right: 20px;
}

@media (max-width: 767.98px) {
    .navbar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.navbar-brand {
    display: contents;
}

.navbar-brand img {
    max-width: 280px;
    transition: all 0.7s;
}

.navbar-brand img:hover {
    transform: scale(1.1);
}

.nav-link {
    color: #000;
    transition: all 0.5s;
}

.drp-icon {
    font-size: 0.8em !important;
}

@media screen and (max-width: 1091px) {
    .nav-link {
        font-size: 0.8em;
    }
}

.nav-link i {
    font-size: 2.8em;
    position: relative;
    /* top: 3px; */
}

.fix-header {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 900;
    /* background-color: transparent; */
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
    transition: All 1s ease;
    position: fixed;
}

.fix-header .fix-men {
    position: fixed;
    z-index: 1029;
    width: 100%;
    top: -313px;
    transition: all 0.7s;
}

.fix-header .fixed-menu {
    padding: 10px 0;
    position: fixed;
    top: 0;
    margin-top: 0px;
    width: 100%;
}

.fix-header .active {
    font-weight: 900;
    color: #000;
}

.fixed-menu {
    background-color: #26231d;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    -moz-box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    /*background-color:rgba(255,255,255,0.9);*/
    position: fixed;
    border: none;
    opacity: 0.85;
}

.fixed-menu .navbar {
    padding: 0;
}

.fixed-menu h1 {
    top: 0;
}

.fixed-menu img {
    width: 200px;
}

.bg-white {
    background-color: #fff;
}

.bg-invi .nav-link {
    color: #fff;
}

.fixed-menu .bg-invi {
    background-color: #26231d !important;
}

.fixed-menu .bg-invi .nav-link {
    color: #fff;
}

.bx-menu {
    color: #000;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 0.15rem;
}

@media screen and (max-width: 991px) {
    .navbar-brand img {
        width: 150px;
    }

    .fixed-menu img {
        width: 70px;
    }
}

@media screen and (max-width: 767px) {
    .fixed-menu {
        padding: 5px 0 0 0;
    }

    .navbar-brand h1 {
        top: 0px;
    }

    .dropdown-menu {
        padding: 0;
        margin: 0;
    }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/

footer {
    background-color: $meio-preto;
}
#footer-info {
    background-color: $meio-preto;
}

#footer-info p {
    color: $cinza-claro;
    font-size: 16px;
    font-weight: 400;
    font-family: Objective;
}

#footer-info span {
    color: $cinza-claro;
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 400;
    font-family: Objective;
}
#footer-info .nav-bot p{
    font-family: Objective;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 21px;
    color: $branco;
}
#footer-info .nav-bot li{
    font-family: Objective;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    list-style: none;
    color: $cinza-claro;
}

#footer-info a {
    color: $cinza-claro;
    text-decoration: none;
}

#footer-info .info-icons {
    font-size: 2em;
    padding: 7px;
    color: $azul-claro;
    position: relative;
    right: 12px;
    top: 2px;
}

#footer-info .social-icons {
    font-size: 2em;
    color: $branco;
}

.footerhr {
    background-color: #acacac;
    width: 100%;
    margin: 0 auto;
    opacity: 1;
    height: 0.5px;
}

/*--------------------------------------------------------------
  # Copyright bottom
  --------------------------------------------------------------*/

#copyright {
    background: $meio-preto;
}

#copyright p {
    font-size: 16px;
    color: $branco;
    font-weight: 400;
    font-family: Objective;
}

#copyright a {
    display: contents;
    color: $branco;
}

#copyright img {
    width: 64px;
}

#copyright .poli {
    transition: all 0.5s;
}

#copyright .poli:hover {
    text-decoration: underline;
}

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
    #footer-info h3 {
        padding: 0px;
    }
    #footer-info .btn-contato-amarelo {
        padding: 10px 30px;
    }
}

@media (max-width: 991.98px) {
    #footer-info {
        margin-bottom: 60px;
    }
    #footer-info .borda-left {
        border-left: none;
    }
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
    #formulario .hr-contato {
        left: 0;
    }
}

@media (max-width: 980px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 370px) {
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
    #footer-info .btn-contato-amarelo {
        padding: 10px 30px;
    }
}
