$azul-claro: #46BED8;
$azul-medio: #277BC0;
$azul-escuro: #3156A3;

$branco: #fff;
$preto: #000;
$meio-preto: #181818;

$cinza:#F6F6F6;
$cinza2: #F0F0F0;
$cinza3:#EFEFEF;
$cinza4: #9F9F9F;

$cinza-claro: #CFDBEC;
$cinza-escuro: #CBCBCB;
$cinza-preto: #666666;

$roxo: #5E358C;


.case-card
{
    transition: all 0.8s;
    height: 20rem;
    color: white;

    .case-txt-1
    {
        // opacity: 1;
        position: relative;
        top: 5rem;
        transition: all 0.8s;
    }

    .case-txt-2
    {
        opacity: 0;
        transition: all 0.8s;
    }
    .case-fundo
    {
        background-color: transparent;
        width: 100%;
        height: 100%;
        transition: all 0.8s;
    }
}

.case-card:hover
{
    .case-txt-1
    {
        // opacity: 0;
        top: -10rem;
    }
    .case-txt-2
    {
        opacity: 1;
    }
    .case-fundo
    {
        background-color: #80008080;
    }
}